import "./LeftAdminPanel.css"
import React,{useContext, useState} from "react";
import {BsPinAngle, BsPinAngleFill} from 'react-icons/bs';
import AdminPanelContext from "../../../Context/AdminPanelContext";
import '../SidePanelsAnimations.css';
import { useEffect } from "react";
import Calculate from "../Calculate";
import { Grid } from "@mui/material";
const LeftAdminPanel = () =>{

    const admin_panel_ctx = useContext(AdminPanelContext);

    const getClassName = direction =>{
        if(direction === 'up'){
            if(admin_panel_ctx.isLeftUpOpen && !admin_panel_ctx.isLeftDownOpen) return "fully_opened";
            else if (admin_panel_ctx.isLeftUpOpen && admin_panel_ctx.isLeftDownOpen) return "half_opened";
            else return "closed";
        }
        else{
            if(!admin_panel_ctx.isLeftUpOpen && admin_panel_ctx.isLeftDownOpen) return "fully_opened";
            else if (admin_panel_ctx.isLeftUpOpen && admin_panel_ctx.isLeftDownOpen) return "half_opened";
            else return "closed";
        }
    }

    useEffect(()=>{
        Calculate();
    }, [admin_panel_ctx.leftPinActive])
    
    useEffect(()=>{
        localStorage.setItem('leftPin','1')
    },[])


    let leaveLeftTimeout; 
    
    const LeaveLeftTimeout = (e) =>{
        leaveLeftTimeout = setTimeout(()=>LeaveActions(e), 500);
    }

    const LeaveActions = (e) =>{
        const wrapperLeft = (e.relatedTarget && e.relatedTarget.closest('#wrapperLeft'))

        if(!admin_panel_ctx.leftPinActive && !wrapperLeft){
            admin_panel_ctx.setIsLeftUpOpen(false);
            admin_panel_ctx.setIsLeftDownOpen(false)
        }
    }


    return(
        <div className={`left_admin_panel ${(admin_panel_ctx.isLeftUpOpen || admin_panel_ctx.isLeftDownOpen) && 'left_open'}`} style={{backgroundColor:admin_panel_ctx.color1}} onMouseLeave={(e)=>LeaveLeftTimeout(e)} onMouseEnter={()=>clearTimeout(leaveLeftTimeout)} >
            <div className={`left_up_panel ${getClassName('up')}`}>
                <div className={`left_up_panel_top`}>
                    {admin_panel_ctx.leftPinActive ? <BsPinAngleFill className={'left_pin_active'} onClick={()=>admin_panel_ctx.setLeftPinActive(false)}/> : <BsPinAngle className={'left_pin'} onClick={()=>admin_panel_ctx.setLeftPinActive(true)}/>}
                </div>
                <div className="modules_main_container scrollerY">
                    {admin_panel_ctx.isLeftUpOpen === 1}
                </div>
            </div>
            <div className={`left_down_panel ${getClassName('down')}`}>
                <div className={`left_down_panel_top`}>
                    { !admin_panel_ctx.isLeftUpOpen &&  (admin_panel_ctx.leftPinActive ? <BsPinAngleFill className={'left_pin_active'} onClick={()=>admin_panel_ctx.setLeftPinActive(false)}/> : <BsPinAngle className={'left_pin'} onClick={()=>admin_panel_ctx.setLeftPinActive(true)}/>) }
                </div>
                <div className="modules_main_container scrollerY">
                    {admin_panel_ctx.isLeftDownOpen === 2 && <Grid container><Grid item xs={12} textAlign={'center'}p={4}>Social Media</Grid><Grid item xs={12} textAlign={'center'}>Marketing Automation</Grid></Grid>}
                </div>
            </div>
        </div>
    )
}

export default LeftAdminPanel;