import { Grid } from "@mui/material";
import {useState } from "react";
import IndexesManagerContext from "../../../../../Context/IndexesManagerContext";
import IndexesManagerAttributesField from "./IndexesManagerAttributesField/IndexesManagerAttributesField";
import IndexesManagerBoxGroupField from "./IndexesManagerBoxGroupField/IndexesManagerBoxGroupField";
import IndexesManagerOperationsField from "./IndexesManagerOperationsField/IndexesManagerOperationsField";
import styles from "./IndexesModalContent.module.css";

const IndexesModalContent =()=>{
    const [attributesList, setAttributesList] = useState([])
    const [boxGroupField, setBoxGroupField] = useState(0)
    const [operationsField, setOperationsField] = useState(1)
    const [productsInGroup, setProductsInGroup] = useState([])
    const [isOnlyOneChosen, setIsOnlyOneChosen] = useState(false)
    const [attributesListInProductsInGroup, setAttributesListInProductsInGroup] = useState([])
    const [attributesListInProductsInFound, setAttributesListInProductsInFound] = useState([])
    const [attributesListInGroupToAdd, setAttributesListInGroupToAdd] = useState([])
    const [attributesListInSingleToAdd, setAttributesListInSingleToAdd] = useState([])
    const [removeAttributesInFoundChecked, setRemoveAttributesInFoundChecked] = useState([]);
    const [removeAttributesInGroupChecked, setRemoveAttributesInGroupChecked] = useState([]);
    const [timer, setTimer] = useState(1)
    const [isControlPressed, setIsControlPressed] = useState(false);

    window.onkeydown = listenToTheKey;
    window.onkeyup = removeProperties;
    function listenToTheKey(e)
    {
        if(e.key === 'Control'){
            setIsControlPressed(true)
        }
    }
    
    function removeProperties(e){setIsControlPressed(false)}
    return(
        <IndexesManagerContext.Provider
        value={{
            attributesList,
            operationsField,
            boxGroupField,
            isOnlyOneChosen,
            productsInGroup,
            isControlPressed,
            attributesListInProductsInGroup,
            attributesListInProductsInFound,
            attributesListInGroupToAdd,
            attributesListInSingleToAdd,
            removeAttributesInFoundChecked,
            removeAttributesInGroupChecked,
            timer,
            setAttributesList,
            setBoxGroupField,
            setOperationsField,
            setIsOnlyOneChosen,
            setProductsInGroup,
            setIsControlPressed,
            setAttributesListInProductsInGroup,
            setAttributesListInProductsInFound,
            setRemoveAttributesInFoundChecked,
            setRemoveAttributesInGroupChecked,
            setAttributesListInGroupToAdd,
            setAttributesListInSingleToAdd,
            setTimer
        }}
        >
        <Grid container sx={{ backgroundColor:'gray'}} justifyContent={'center'} id={'IndexesManagerContent'} className="zoomOnShow">
            <Grid container item xs={12}>
                <Grid item xs={3} id="IndexesManagerAttributesFieldContainer" sx={{boxShadow:'#1f1f1f 0 1px 10px 0', overflowY:'auto'}} className="scrollerY" ><IndexesManagerAttributesField/></Grid>
                <Grid item xs={9} id="IndexesManagerOperationsFieldContainer" sx={{overflowY:'auto'}} className="scrollerY"><IndexesManagerOperationsField/></Grid>
            </Grid>
        </Grid>
        </IndexesManagerContext.Provider>
    )
}
export default IndexesModalContent;