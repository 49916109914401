import Button from '@mui/material/Button';
import styles from "../AuthForm.module.css";
import { BiLoaderAlt } from 'react-icons/bi';
import { GET_ORGANIZATION_DATA, GET_USER_ORGANIZATIONS, LOGIN_URL } from "../../../Constants";
import { Link, useNavigate } from "react-router-dom";
import AuthTemplate from "../AuthTemplate/AuthTemplate";
import CookiesContext from "../../../Context/CookiesContext";
import {useContext, useEffect, useRef, useState} from "react";
import LogInPostDataHandler from "../../FetchMethods/LogInPostDataHandler";
import { Alert, AlertTitle, Slide, Snackbar, TextField, Typography } from "@mui/material";
import PostMethod from '../../FetchMethods/PostMethod';

const Login = () => {

    const enteredUsername = useRef()
    const enteredPassword = useRef()
    const [isValid, setIsValid] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const cookies_ctx = useContext(CookiesContext);
    let navigate = useNavigate();


    useEffect(()=>{
        if(typeof(cookies_ctx.GetCookie("token"))==='string' && localStorage.getItem("currentOrganization")) return navigate('/dashboard');
    })

    const onSubmitHandler = event =>{
        event.preventDefault();
        let userData = {
            username:enteredUsername.current.value,
            password:enteredPassword.current.value
        };
        setIsLoading(true);
        LogInPostDataHandler(LOGIN_URL, userData, responseHandler)
    }

    const setOrganizations =(data)=>{
        let array=[]
        Object.keys(data).forEach(e => {
            array.push(parseInt(e));
        });
        localStorage.setItem("currentLogo",data[Object.keys(data)[0]]['config']['logo'])
        cookies_ctx.SetCookie("userOrganizations",array, "/", 'httpOnly');
        localStorage.setItem("currentOrganization", JSON.stringify(data[array[0]]));
        // document.documentElement.requestFullscreen();
        return navigate('/dashboard');
    }

    const responseHandler = (response) => {
        const token = response.token;
        if (typeof(token)==='string'){
            cookies_ctx.SetCookie("token", token, "/", 'httpOnly');
            setIsInvalid(false);
            setIsValid(true);
            PostMethod(GET_USER_ORGANIZATIONS, {email:cookies_ctx.DecodeToken(response.token).username}, setOrganizations);
        }
        else setIsInvalid(true);
    }

    return (
        <>
            {<Snackbar open={isValid} autoHideDuration={3000} onClose={()=>{setIsValid(false); setIsLoading(false)}} TransitionComponent={Slide} anchorOrigin={{vertical:'top', horizontal:'center'}} >
                        <Alert style={{backgroundColor:'lightgreen'}} sx={{ width: '100%' }}>
                        <AlertTitle>Successfull</AlertTitle>
                            Logged in successfully !!! 
                        </Alert>
                    </Snackbar>
            }
            {<Snackbar open={isInvalid} autoHideDuration={3000} onClose={()=>{setIsInvalid(false); setIsLoading(false)}} TransitionComponent={Slide} anchorOrigin={{vertical:'top', horizontal:'center'}} >
                        <Alert severity="error" style={{backgroundColor:'lightcoral'}} sx={{ width: '100%' }}>
                        <AlertTitle>Error</AlertTitle>
                            Wrong email or password !!! 
                        </Alert>
                    </Snackbar>
            }
            <AuthTemplate>
                <Typography className={styles.typography} variant="h2" color="white" >Log in</Typography>
                {isLoading && !isInvalid && <BiLoaderAlt className={styles.loading_icon}/>}
                <form onSubmit={onSubmitHandler}>
                    <div className={styles.login_controls}>
                        <div className={styles.login_inputs}>
                            <TextField className={styles.login_input} sx={{label: {color: "white"}, input: {color: "white"}}} label="Email" variant="standard" type="email" inputRef={enteredUsername} required={true}/>
                            <TextField className={styles.login_input} sx={{label: {color: "white"}, input: {color: "white"}}} label="Password" pattern ="(?=.*\d)(?=.*[\W_]).{5,}" variant="standard" type="password" inputRef={enteredPassword} required={true}/>
                        </div>
                        <div>Don't have an account ? <Link to="/signup" className={styles.sign_in_href}>Sign up</Link></div>
                    </div>
                    <div className={styles.login_actions}>
                        <Button color='info' variant="contained" size="large" type="submit" >Login</Button>
                    </div>
                </form>
            </AuthTemplate>
        </>
    )
}
export default Login;