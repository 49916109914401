import { useContext, useState } from "react";
import AdminPanelContext from "../../../Context/AdminPanelContext";
import {AiOutlineClose} from 'react-icons/ai';
import { useEffect } from "react";
import ModulesBarManager from "../../Modules/ModulesBarManager";
import "./ModulesBar.css"

const ModulesBar =()=>{
    const admin_panel_ctx = useContext(AdminPanelContext);
    const [modulesBar, setModulesBar] = useState();
    const [active, setActive] = useState();

    useEffect(()=>{
        if(localStorage.getItem("ModulesBar")){
            const modulesBar = JSON.parse(localStorage.getItem("ModulesBar"));
            setModulesBar(modulesBar);
            let currentActive = modulesBar.find(x=>x.active===true)
            if(currentActive) setActive(currentActive.label)
        }
    }, [localStorage.getItem("ModulesBar")])
    

    const closeModule =(event,label, componentType, id) =>{
        event.stopPropagation();
        if(componentType === "Attributes"){admin_panel_ctx.setIsAttributesOpen(false)}
        else if(componentType === "Languages"){admin_panel_ctx.setIsLanguagesOpen(false)}
        else if(componentType === "ImportIndexesList"){admin_panel_ctx.setIsImportIndexesListOpen(false)}
        else if(componentType === "IndexesList"){admin_panel_ctx.setIsIndexesListOpen(false)}
        else if(componentType === "IndexesAttributesManager"){admin_panel_ctx.setIsIndexesAttributesManagerOpen(false)}
        else if(componentType === "Category"){admin_panel_ctx.setIsCategoryOpen(false)}
        ModulesBarManager(label, "pop", {componentType:componentType, id:id&&id})
        admin_panel_ctx.setReloadModulesBar(admin_panel_ctx.reloadModulesBar + 1);
    }

    const showModule = (label, componentType, id) =>{
        ModulesBarManager(label, "show", {componentType:componentType, id:id&&id})
        admin_panel_ctx.setReloadModulesBar(admin_panel_ctx.reloadModulesBar + 1);
    }

    const ListItem = props => {
        return(
            <div className={'modulesBar_list_item'} style={props.active ? {backgroundColor:admin_panel_ctx.color2} :{backgroundColor:admin_panel_ctx.color1}} onClick={()=>showModule(props.label, props.componentType, props.id && props.id)}>
                <li id={props.name}>{props.name.replace(/(<([^>]+)>)/gi, "")}</li>
                <AiOutlineClose size={10} className={'modulesBar_list_item_close_icon'} onClick={(e)=>closeModule(e, props.label, props.componentType, props.id && props.id)}/>
            </div>
        );
    }

    const getModule=(object)=>{
        switch (object.config.componentType) {
            case "Attributes":
                return <ListItem key={object.label} name="Atrybuty" label="Attributes" active={object.active} componentType={'Attributes'}/>
            case "Languages":
                return <ListItem key={object.label} name="Języki" label="Languages" active={object.active} componentType={'Languages'}/>
            case "ImportIndexesList":
                return <ListItem key={object.label} name="Lista indexów importu" label="ImportIndexesList" active={object.active} componentType={'ImportIndexesList'}/>
            case "IndexesList":
                return <ListItem key={object.label} name="Produkty" label="IndexesList" active={object.active} componentType={'IndexesList'}/>
            case "IndexesAttributesManager":
                return <ListItem key={object.label} name="Manager attrybutów - indexy" label="IndexesAttributesManager" active={object.active} componentType={'IndexesAttributesManager'}/>
            case "Category":
                return <ListItem key={object.label} name="Kategorie" label="Category" active={object.active} componentType={'Category'}/>
            case "ProductEdit":
                return <ListItem key={object.config.id} name={object.label} label={object.label} id={object.config.id} active={object.active} componentType={'ProductEdit'}/>
            case "CategoryEdit":
                return <ListItem key={`Category-${object.config.id}`} name={object.label} label={object.label} id={object.config.id} active={object.active} componentType={'CategoryEdit'}/>
            default:
                break;
        }
    }

    useEffect(()=>{
        let logo = document.getElementById('logo');
        let bar = document.getElementById('modules_bar');
        logo.addEventListener("animationend", ()=>{ bar.classList.add('modules_bar_animation')}, false);
    })



    return (
        <div id="modules_bar" className={`modulesBar_container`} style={admin_panel_ctx.IfAnyModuleOpen ?{backgroundColor:admin_panel_ctx.cElement.negative, display:'block'}:{display:'none'}}>
            <ul className={'modulesBar_list'}>
                {modulesBar && modulesBar.map((mod)=>{
                    return (
                        getModule(mod)
                    )
                })}
            </ul>
        </div>
    );
}
export default ModulesBar;