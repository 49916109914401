import styles from "./PIM.module.css"
import { Grid,ListItemIcon, MenuItem, MenuList, Divider } from "@mui/material"
import BookmarkIcon from '@mui/icons-material/Bookmark';
import LanguageIcon from '@mui/icons-material/Language';
import { useContext } from "react";
import AdminPanelContext from "../../../../Context/AdminPanelContext";
import { useState } from "react";
import IndexList from "./IndexList/IndexList";
import { BiImport } from 'react-icons/bi';
import { RiAdminFill } from 'react-icons/ri';
import Index from "./Index/Index";
import Box from "./Box/Box";
import AdminButtons from "./AdminButtons/AdminButtons";

const PIM =()=>{
    const admin_panel_ctx = useContext(AdminPanelContext);
    const [bookmarks, setBookmarks] = useState({
        isIndexListOpen:false,
        isIndexOpen:false,
        isBoxOpen:false,
        isAdminOpen:false,
    });

    const getOrganizationPrivileges = ()=>{
        if(JSON.parse(localStorage.getItem('currentOrganization'))){
            return JSON.parse(localStorage.getItem('currentOrganization')).config.privileges;
        }
    } 

    return(
        <Grid className={styles.content} container>
            <Grid container item xs={2} style={{overflowY:'auto', height:'100%'}} className={'scrollerY scrollerX'} >
            <Grid p={0} pt={2} xs={12} item>
                <MenuList style={{padding:0}}>

                    <MenuItem className={styles.menu_item} onClick={()=>setBookmarks((prev)=>{return {isIndexOpen:!prev.isIndexOpen}})}>
                        <ListItemIcon>
                            <LanguageIcon sx={{color:bookmarks.isIndexOpen?"#1976D2":"silver", paddingLeft:'7px'}} fontSize="medium"/>
                        </ListItemIcon>
                    </MenuItem>
                    
                    <Divider sx={{marginTop:'5px !important', marginBottom:'5px !important'}}/>

                    <MenuItem className={styles.menu_item} onClick={()=>setBookmarks((prev)=>{return {isIndexListOpen:!prev.isIndexListOpen}})}>
                        <ListItemIcon>
                            <BiImport style={{paddingLeft:'7px'}} size={20} color={bookmarks.isIndexListOpen?"#1976D2":"silver"}/>
                        </ListItemIcon>
                    </MenuItem>

                    <MenuItem className={styles.menu_item} onClick={()=>setBookmarks((prev)=>{return {isBoxOpen:!prev.isBoxOpen}})}>
                        <ListItemIcon>
                            <BookmarkIcon sx={{color:bookmarks.isBoxOpen?"#1976D2":"silver", paddingLeft:'7px'}} fontSize="medium"/>
                        </ListItemIcon>
                    </MenuItem>
                    {getOrganizationPrivileges().pim.components['import-tools'] && 
                        <MenuItem className={styles.menu_item} onClick={()=>setBookmarks((prev)=>{return {isAdminOpen:!prev.isAdminOpen}})}>
                            <ListItemIcon>
                                <RiAdminFill style={{color:bookmarks.isAdminOpen?"#1976D2":"silver", paddingLeft:'7px'}} size={20} fontSize="medium"/>
                            </ListItemIcon>
                        </MenuItem>
                    }
                </MenuList>
                </Grid>
            </Grid>
        <Grid container item p={1} xs={10} style={{backgroundColor:admin_panel_ctx.cSheet.default,overflowY:'auto', height:'100%', color:admin_panel_ctx.cText.default}} className={'scrollerY scrollerX'}>
            {bookmarks.isIndexListOpen && <div className={styles.animation}><IndexList/></div>}
            {bookmarks.isIndexOpen && <div className={styles.animation}><Index/></div>}
            {bookmarks.isBoxOpen && <div className={styles.animation}><Box/></div>}
            {bookmarks.isAdminOpen && <div className={styles.animation}><AdminButtons/></div>}
        </Grid>
    </Grid>
    )
}

export default PIM;