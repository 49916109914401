import jwtDecode from "jwt-decode";
import { Route, Routes } from "react-router-dom";
import CookiesContext from "./Context/CookiesContext";
import { CookiesProvider, useCookies } from "react-cookie";
import Login from "./Components/Authentication/Login/Login";
import AdminPanel from "./Components/AdminPanel/AdminPanel";
import SignUp from "./Components/Authentication/SignUp/SignUp";
import ExpectedLoggedIn from "./Components/WarningPages/ExpectedLoggedIn/ExpectedLoggedIn";

const App = () => {

  const [cookies, setCookies, removeCookie] = useCookies();

  const GetCookie = (cookieName) => {
    return cookies[cookieName];
  };

  const SetCookie = (
    cookieName,
    cookieValue,
    cookiePath = null,
    options = null
  ) => {
    setCookies(cookieName, cookieValue, { path: cookiePath }, options);
  };

  const RemoveCookie = (cookieName) => {
    removeCookie(cookieName);
  };

  const DecodeToken = (token) => {
    return jwtDecode(token);
  };

  return (
    <CookiesProvider>
      <CookiesContext.Provider
      value={{
        GetCookie,
        SetCookie,
        RemoveCookie,
        DecodeToken,
      }}
      >
      <Routes>
        <Route path="/" element={<Login/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path="/signup" element={<SignUp/>}/>
        <Route path="/dashboard" element={<AdminPanel/>}/>
        <Route path="/expected-logged-in" element={<ExpectedLoggedIn/>}/>
      </Routes>
      </CookiesContext.Provider>
    </CookiesProvider>
  );
}
export default App;
