import { Grid } from "@mui/material";
import CategoryEditOptionsComponent from "../CategoryEditComponents/CategoryEditOptionsComponent/CategoryEditOptionsComponent";
import CategoryEditFieldsComponent from "../CategoryEditComponents/CategoryEditFieldsComponent/CategoryEditFieldsComponent";

const DefaultCategoryEditElement=()=>{
    return(
        <Grid container>
            <Grid item xs={2}>
                <CategoryEditOptionsComponent/>
            </Grid>
            <Grid item xs={10}>
                <CategoryEditFieldsComponent/>
            </Grid>
        </Grid>
    )
}
export default DefaultCategoryEditElement;