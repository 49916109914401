import { useContext, useEffect, useState } from "react";
import styles from "./MainAdminPanel.module.css"
import AdminPanelContext from "../../../Context/AdminPanelContext";
import Attributes from "../../Modules/BaseModules/Attributes/Bulks";
import Languages from "../../Modules/BaseModules/Languages/Languages";
import ImportIndexesList from "../../Modules/BaseModules/PIM/Index/ImportIndexesList/ImportIndexesList";
import IndexesListModule from "../../Modules/BaseModules/PIM/IndexList/IndexesListModule/IndexesListModule";
import IndexesModalContent from "../../Modules/BaseModules/IndexesManager/IndexesModalContent/IndexesModalContent";
import IndexListProductEdit from "../../Modules/BaseModules/PIM/IndexList/IndexListProductEdit/IndexListProductEdit";
import Category from "../../Modules/BaseModules/PIM/IndexList/Category/Category";
import CategoryEditElement from "../../Modules/BaseModules/PIM/IndexList/Category/CategoryFields/CategoriesList/CategoryEditElement/CategoryEditElement";

const MainAdminPanel = () =>{
    const admin_panel_ctx = useContext(AdminPanelContext);

    const getComponentType = (object) => {
        switch (object.config.componentType) {
            case "Attributes":
                return <Attributes/>
            case "Languages":
                return <Languages/>
            case "ImportIndexesList":
                return <ImportIndexesList/>
            case "IndexesList":
                return <IndexesListModule/>
            case "IndexesAttributesManager":
                return <IndexesModalContent/>
            case "Category":
                return <Category/>
            case "ProductEdit":
                return <IndexListProductEdit object={object}/>
            case "CategoryEdit":
                return <CategoryEditElement object={object}/>
            default:
                break;
        }
    }

    return (
        <div id={'main_admin_panel'} className={`${styles.main_admin_panel} fullSize`} style={ admin_panel_ctx.IfAnyModuleOpen ? {backgroundColor:''} : {backgroundColor:''} }>
            {JSON.parse(localStorage.getItem("ModulesBar")) && JSON.parse(localStorage.getItem("ModulesBar")).map((module,i)=>{
                return <div key={i} style={ !module.active ? {display:'none'}: {display:'block'}}>{getComponentType(module)}</div>
            })}
        </div>
    )
}
export default MainAdminPanel;