import { Grid, TextareaAutosize, Typography } from "@mui/material";
import styles from "./BulletPoints.module.css"
import {RiArrowUpDownLine} from "react-icons/ri"
import { useContext } from "react";
import AdminPanelContext from "../../../../../../../../../Context/AdminPanelContext";


const BulletPoints =()=>{
    const admin_panel_ctx = useContext(AdminPanelContext)

    const row = (attribute, content, sortBy) =>{
            return(
                <Grid container>
                    <Grid item xs={3} className={styles.cell_container} p={2}>{attribute}</Grid>
                    <Grid item xs={7} className={styles.cell_container} p={2}>{content}</Grid>
                    <Grid item xs={2} className={styles.cell_container} p={2}>{sortBy}</Grid>
                </Grid>
            )
    }

    const attributes = [{attribute:'Atrybut', content:'treść/wartość', sortBy:'sortBy'},{attribute:'Atrybut', content:'treść/wartość', sortBy:'sortBy'},{attribute:'Atrybut', content:'treść/wartość', sortBy:'sortBy'},{attribute:'Atrybut', content:'treść/wartość', sortBy:'sortBy'}]

    return (
        <Grid container>
            <Grid item xs={12}><Typography fontSize={30} textAlign={'center'} mt={2}>Opis</Typography></Grid>
            <TextareaAutosize minRows={10} style={{backgroundColor:admin_panel_ctx.cPanel.default, width:'100%', padding:'10px', margin:'20px'}}/>
            <Grid container item xs={12} pt={3} textAlign={"center"} mx={3}>
                <Grid item xs={3} className={styles.cell_header_container} p={2}><Typography fontSize={20} fontWeight={700}>Atrybuty</Typography></Grid>
                <Grid item xs={7} className={styles.cell_header_container} p={2}><Typography fontSize={20} fontWeight={700}>Treść/Wartość</Typography></Grid>
                <Grid item xs={2} className={styles.cell_header_container} p={2}><Typography fontSize={20} fontWeight={700}><RiArrowUpDownLine size={30}/></Typography></Grid>
                {attributes.map(atr => {return row(atr.attribute, atr.content, atr.sortBy)})}
            </Grid>
        </Grid>
    )
}
export default BulletPoints;