const env = window.location.href.split('/')[2];
let db_constant;
if(env === "localhost:3000" || env === 'admin-dev.xperto.pl'){
    db_constant = 'admin-api';
}
else if (env === "admin.xperto.pl"){
    db_constant = 'api';
}


export const SIGN_UP_URL = `https://${db_constant}.xperto.pl/api/register`;
export const LOGIN_URL = `https://${db_constant}.xperto.pl/api/login_check`;
export const GET_USER_ORGANIZATIONS = `https://${db_constant}.xperto.pl/data/get-user-organizations`;
export const GET_ALL_ORGANIZATIONS = `https://${db_constant}.xperto.pl/data/get-organizations`;
export const GET_PROJECTS = `https://${db_constant}.xperto.pl/env/get-projects`;

//ATRIBUTES
export const GET_BULKS_URL = `https://${db_constant}.xperto.pl/attributes/get-bulks`;
export const SET_BULKS_URL = `https://${db_constant}.xperto.pl/attributes/set-bulks`;
export const UPDATE_ATTRIBUTES_URL = `https://${db_constant}.xperto.pl/attributes/update-bulk`;
export const GET_ATTRIBUTES_GROUP_URL = `https://${db_constant}.xperto.pl/attributes/get-attributes-group`;
export const SET_ATTRIBUTES_GROUP_URL = `https://${db_constant}.xperto.pl/attributes/set-attributes-group`;
export const UPDATE_ATTRIBUTES_GROUP_URL = `https://${db_constant}.xperto.pl/attributes/update-attributes-group`;
export const GET_ATTRIBUTES_URL = `https://${db_constant}.xperto.pl/attributes/get-attributes`;
export const SET_ATTRIBUTE_URL = `https://${db_constant}.xperto.pl/attributes/set-attribute`;
export const UPDATE_ATTRIBUTE_URL = `https://${db_constant}.xperto.pl/attributes/update-attribute`;

//IMPORT INDEXES LIST
export const GET_IMPORTED_INDEXES = `https://${db_constant}.xperto.pl/import/get-import-indexes`;
export const CHANGE_IMPORT_INDEX_STATUS = `https://${db_constant}.xperto.pl/import/change-import-index-status`;
export const WAREHOUSE_GET_ITEMS_STATUS = `https://${db_constant}.xperto.pl/warehouse/get-items`;

//IMPORT PROCESSOR
export const PREPARE_QUEUE_URL = `https://${db_constant}.xperto.pl/import-processor/prepare-queue`;
export const PROCESS_QUEUE_URL = `https://${db_constant}.xperto.pl/import-processor/process-queue`;
export const DEPLOY_URL = `https://${db_constant}.xperto.pl/deploy/indexes`;
export const DEPLOY_KILL_URL = `https://${db_constant}.xperto.pl/deploy/kill`;

//WAREHOUSE
export const WAREHOUSE_SET_ITEM_ACTIVITY_URL = `https://${db_constant}.xperto.pl/warehouse/set-item-activity`;

//IMPORT INDEXES MANAGER
export const SEARCH_INDEX_MANAGER_PRODUCTS = `https://${db_constant}.xperto.pl/attribute-manager/index-search`;
export const GET_INDEX_ATTRIBUTES_URL = `https://${db_constant}.xperto.pl/attribute-manager/get-index-attributes`;
export const UPDATE_INDEX_ATTRIBUTES_URL = `https://${db_constant}.xperto.pl/attribute-manager/update-index-attributes`;

//PRODUCT EDIT
export const GET_PRODUCT_EDIT_NAME = `https://${db_constant}.xperto.pl/product-basics/get-name`;
export const GET_PRODUCT_EDIT_URI = `https://${db_constant}.xperto.pl/product-seo/get-uri`;
export const GET_PRODUCT_EDIT_DESCRIPTION = `https://${db_constant}.xperto.pl/product-seo/get-description`;
export const SET_PRODUCT_EDIT_DESCRIPTION = `https://${db_constant}.xperto.pl/product-seo/set-description`;
export const GET_PRODUCT_EDIT_ROBOTS = `https://${db_constant}.xperto.pl/product-seo/get-robots`;
export const SET_PRODUCT_EDIT_ROBOTS = `https://${db_constant}.xperto.pl/product-seo/set-robots`;

export const GET_PRODUCT_EDIT_CKE_DESCRIPTION = `https://${db_constant}.xperto.pl/product-text/get-text`;
export const SET_PRODUCT_EDIT_CKE_DESCRIPTION = `https://${db_constant}.xperto.pl/product-text/set-text`;

//CATEGORY
export const GET_CATEGORY_LIST = `https://${db_constant}.xperto.pl/category-list/get-list`;

