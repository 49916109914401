import { useContext } from 'react';
import AdminPanelContext from '../../../../../Context/AdminPanelContext';
import styles from './SettingsPanel.module.css';
import {RiCloseFill} from 'react-icons/ri';
import { createPortal } from 'react-dom';
import { Grid, Typography } from '@mui/material';

const SettingsPanel = (props) => {

    const admin_panel_ctx = useContext(AdminPanelContext);

    return(
        <>
            {createPortal(
            <div className={styles.settings_main_panel}>
                <div className={styles.settings_main_panel_close_icon_container}>{<RiCloseFill className={styles.settings_main_panel_close_icon} size={25} onClick={()=>props.closeModal(false)}/>}</div>
                <div className={styles.settings_main_panel_content}>
                    <Grid container justifyContent={'center'}>
                        <Grid container item xs={12} color={'black'}>
                                <Grid item xs={12} mb={5} textAlign="center"><Typography fontSize={30} sx={{color:'black'}}>Kolor Matka</Typography></Grid>
                                <Grid container item xs={12}>
                                    <Grid item xs={12} mb={5} textAlign="center"><input className={styles.settings_change_color_input} value={admin_panel_ctx.colorRoot} type={'color'} onChange={(e)=>{admin_panel_ctx.setColorRoot(e.target.value)}}/></Grid>
                                </Grid>
                            {/* MAIN COLORS */}
                            <Grid container xs={12} m={0} p={0} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                <Grid item xs={4} sx={{backgroundColor:'#d0d0d0', height:'50px', border:'solid silver 1px'}} display={'flex'} justifyContent={'center'} alignItems={'center'}><Typography fontSize={20}>kolor 1</Typography></Grid>
                                <Grid item xs={4} sx={{backgroundColor:'#d0d0d0', height:'50px', border:'solid silver 1px'}} display={'flex'} justifyContent={'center'} alignItems={'center'}><Typography fontSize={20}>kolor 2</Typography></Grid>
                                <Grid item xs={4} sx={{backgroundColor:'#d0d0d0', height:'50px', border:'solid silver 1px'}} display={'flex'} justifyContent={'center'} alignItems={'center'}><Typography fontSize={20}>kolor 3</Typography></Grid>
                            </Grid>
                            <Grid container xs={12} mb={5} p={0} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                <Grid item xs={4} sx={{backgroundColor:'#d0d0d0', height:'50px', border:'solid silver 1px'}} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <Grid container item>
                                        <Grid item xs={12} textAlign="center"><input className={styles.settings_change_color_input} value={admin_panel_ctx.color1} type={'color'} onChange={(e)=>{admin_panel_ctx.setColor1(e.target.value)}}/></Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} sx={{backgroundColor:'#d0d0d0', height:'50px', border:'solid silver 1px'}} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <Grid container item>
                                        <Grid item xs={12} textAlign="center"><input className={styles.settings_change_color_input} value={admin_panel_ctx.color2} type={'color'} onChange={(e)=>{admin_panel_ctx.setColor2(e.target.value)}}/></Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} sx={{backgroundColor:'#d0d0d0', height:'50px', border:'solid silver 1px'}} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <Grid container item>
                                        <Grid item xs={12} textAlign="center"><input className={styles.settings_change_color_input} value={admin_panel_ctx.color3} type={'color'} onChange={(e)=>{admin_panel_ctx.setColor3(e.target.value)}}/></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* HEADERS */}
                            <Grid container xs={12} m={0} p={0} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={2} sx={{backgroundColor:'#d0d0d0', height:'50px', border:'solid silver 1px'}} display={'flex'} justifyContent={'center'} alignItems={'center'}><Typography fontSize={20}>text</Typography></Grid>
                                <Grid item xs={2} sx={{backgroundColor:'#d0d0d0', height:'50px', border:'solid silver 1px'}} display={'flex'} justifyContent={'center'} alignItems={'center'}><Typography fontSize={20}>płótno</Typography></Grid>
                                <Grid item xs={2} sx={{backgroundColor:'#d0d0d0', height:'50px', border:'solid silver 1px'}} display={'flex'} justifyContent={'center'} alignItems={'center'}><Typography fontSize={20}>panel</Typography></Grid>
                                <Grid item xs={2} sx={{backgroundColor:'#d0d0d0', height:'50px', border:'solid silver 1px'}} display={'flex'} justifyContent={'center'} alignItems={'center'}><Typography fontSize={20}>element</Typography></Grid>
                            </Grid>
                            {/* POSITIVE */}
                            <Grid container xs={12} m={0} p={0} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                <Grid item xs={2} sx={{backgroundColor:'#d0d0d0', height:'50px', border:'solid silver 1px'}} display={'flex'} justifyContent={'center'} alignItems={'center'}><Typography fontSize={20}>Pozytyw</Typography></Grid>
                                <Grid item xs={2} sx={{backgroundColor:'#d0d0d0', height:'50px', border:'solid silver 1px'}} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <Grid container item xs={12}>
                                        <Grid item xs={12} textAlign="center"><input className={styles.settings_change_color_input} value={admin_panel_ctx.cText.default} type={'color'} onChange={(e)=>{admin_panel_ctx.setCText({default:e.target.value, negative:admin_panel_ctx.cText.negative})}}/></Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2} sx={{backgroundColor:'#d0d0d0', height:'50px', border:'solid silver 1px'}} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <Grid container item xs={12}>
                                        <Grid item xs={12} textAlign="center"><input className={styles.settings_change_color_input} value={admin_panel_ctx.cSheet.default} type={'color'} onChange={(e)=>{admin_panel_ctx.setCSheet({default:e.target.value, negative:admin_panel_ctx.cSheet.negative})}}/></Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2} sx={{backgroundColor:'#d0d0d0', height:'50px', border:'solid silver 1px'}} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <Grid container item xs={12}>
                                        <Grid item xs={12} textAlign="center"><input className={styles.settings_change_color_input} value={admin_panel_ctx.cPanel.default} type={'color'} onChange={(e)=>{admin_panel_ctx.setCPanel({default:e.target.value, negative:admin_panel_ctx.cPanel.negative})}}/></Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2} sx={{backgroundColor:'#d0d0d0', height:'50px', border:'solid silver 1px'}} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <Grid container item xs={12}>
                                        <Grid item xs={12} textAlign="center"><input className={styles.settings_change_color_input} value={admin_panel_ctx.cElement.default} type={'color'} onChange={(e)=>{admin_panel_ctx.setCElement({default:e.target.value, negative:admin_panel_ctx.cElement.negative})}}/></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* NEGATIVE */}
                            <Grid container xs={12} m={0} p={0} display={'flex'} justifyContent={'center'} alignItems={'center'} mb={3}>
                                <Grid item xs={2} sx={{backgroundColor:'#d0d0d0', height:'50px', border:'solid silver 1px'}} display={'flex'} justifyContent={'center'} alignItems={'center'}><Typography fontSize={20}>Negatyw</Typography></Grid>
                                <Grid item xs={2} sx={{backgroundColor:'#d0d0d0', height:'50px', border:'solid silver 1px'}} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <Grid container item xs={12}>
                                        <Grid item xs={12} textAlign="center"><input className={styles.settings_change_color_input} value={admin_panel_ctx.cText.negative} type={'color'} onChange={(e)=>{admin_panel_ctx.setCText({default:admin_panel_ctx.cText.default, negative:e.target.value})}}/></Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2} sx={{backgroundColor:'#d0d0d0', height:'50px', border:'solid silver 1px'}} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <Grid container item xs={12}>
                                        <Grid item xs={12} textAlign="center"><input className={styles.settings_change_color_input} value={admin_panel_ctx.cSheet.negative} type={'color'} onChange={(e)=>{admin_panel_ctx.setCSheet({default:admin_panel_ctx.cSheet.default, negative:e.target.value})}}/></Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2} sx={{backgroundColor:'#d0d0d0', height:'50px', border:'solid silver 1px'}} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <Grid container item xs={12}>
                                        <Grid item xs={12} textAlign="center"><input className={styles.settings_change_color_input} value={admin_panel_ctx.cPanel.negative} type={'color'} onChange={(e)=>{admin_panel_ctx.setCPanel({default:admin_panel_ctx.cPanel.default, negative:e.target.value})}}/></Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2} sx={{backgroundColor:'#d0d0d0', height:'50px', border:'solid silver 1px'}} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <Grid container item xs={12}>
                                        <Grid item xs={12} textAlign="center"> <input className={styles.settings_change_color_input} value={admin_panel_ctx.cElement.negative} type={'color'} onChange={(e)=>{admin_panel_ctx.setCElement({default:admin_panel_ctx.cElement.default, negative:e.target.value})}}/></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                {console.log(admin_panel_ctx.cText)}
            </div>, document.getElementById('modal'))}

            {createPortal(<div className={styles.backdrop} onClick={()=>props.closeModal(false)}/>, document.getElementById('backdrop'))}
        </>
    )
}

export default SettingsPanel;