import { Button, Divider, Grid, IconButton } from "@mui/material";
import styles from "./ProductEditOptionsComponent.module.css" 
import { useContext } from "react";
import ProductEditContext from "../../../../../../../../Context/ProductEditContext";

export const FieldOptions = [{id:1, name:'Dane Podstawowe'}, {id:2, name:'Metadata'}, {id:3, name:'Bullet points'}, {id:4, name:'Media'}, {id:5, name:"Opis"}, {id:6, name:"Atrybuty"}]

const ProductEditOptionsComponent =() =>{

    const product_edit_context = useContext(ProductEditContext)

    
    return (
        <Grid container className={`${styles.ProductEditOptionsField} ProductEditOptionsField`} p={1} color={'white'}>
            <IconButton sx={{ py: '10px' }} aria-label="menu">
                <img src="/images/flags/pl.png" width={'35px'} height={'35px'} style={{border:'1px solid gray', borderRadius:'30px'}}/>
            </IconButton>
            <Divider/>
            {FieldOptions.map(opt=>{
                return <Grid item xs={12} mt={1} key={opt.id}><Button className={styles.fieldButton} variant={product_edit_context.selectedOperationField === opt.id ? "contained" : "standard" } onClick={()=>{product_edit_context.setSelectedOperationField(opt.id)}} fullWidth>{opt.name}</Button></Grid>
            })}
        </Grid>
    )
}
export default ProductEditOptionsComponent;