import { Grid, ListItemIcon, ListItemText, MenuItem, MenuList, Typography } from "@mui/material"
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { useContext } from "react";
import AdminPanelContext from "../../../../../Context/AdminPanelContext";
import ModulesBarManager from "../../../ModulesBarManager";
import { Category } from "@mui/icons-material";

const IndexList = () =>{
    const admin_panel_ctx = useContext(AdminPanelContext);

    const indexesListClick =() =>{
        if(admin_panel_ctx.isIndexesListOpen){
            ModulesBarManager("IndexesList", "show", {componentType:"IndexesList"})
        }
        else{
            admin_panel_ctx.setIsIndexesListOpen(true);
            ModulesBarManager("IndexesList", "push", {componentType:"IndexesList"});
        }
        admin_panel_ctx.setReloadModulesBar(admin_panel_ctx.reloadModulesBar + 1);
    }
    const categoryClick =() =>{
        if(admin_panel_ctx.isCategoryOpen){
            ModulesBarManager("Category", "show", {componentType:"Category"})
        }
        else{
            admin_panel_ctx.setIsCategoryOpen(true);
            ModulesBarManager("Category", "push", {componentType:"Category"});
        }
        admin_panel_ctx.setReloadModulesBar(admin_panel_ctx.reloadModulesBar + 1);
    }
    return(
        <Grid container>
            <Grid p={0} xs={12} item>
                <MenuList sx={{padding:0}}>

                    <MenuItem onClick={indexesListClick}>
                        <ListItemIcon>
                            <BookmarkIcon sx={{color:"silver"}} fontSize="medium"/>
                        </ListItemIcon>
                        <ListItemText align="left"><Typography fontSize={12} color={admin_panel_ctx.cText.negative}>Produkty</Typography></ListItemText>
                    </MenuItem>

                    <MenuItem onClick={categoryClick}>
                        <ListItemIcon>
                            <Category sx={{color:"silver"}} fontSize="medium"/>
                        </ListItemIcon>
                        <ListItemText align="left"><Typography fontSize={12} color={admin_panel_ctx.cText.negative}>Kategorie</Typography></ListItemText>
                    </MenuItem>

                </MenuList>
            </Grid>
        </Grid>
    )
}
export default IndexList;