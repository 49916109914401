import { Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { GET_INDEX_ATTRIBUTES_URL } from "../../../../../../../Constants";
import AdminPanelContext from "../../../../../../../Context/AdminPanelContext";
import IndexesManagerContext from "../../../../../../../Context/IndexesManagerContext";
import Calculate from "../../../../../../AdminPanel/Calculate";
import PostMethod from "../../../../../../FetchMethods/PostMethod";
import IndexesManagerBoxGroupField from "../../IndexesManagerBoxGroupField/IndexesManagerBoxGroupField";
import styles from "./IndexesManagerFoundField.module.css"
import {MdOutlineNoPhotography} from "react-icons/md";

const IndexesManagerFoundField = (props) =>{
    const indexes_manager_ctx = useContext(IndexesManagerContext);
    const [attributesToRefresh, setAttributesToRefresh] = useState();
    const [searchInput, setSearchInput] = useState('');
    const [checkedProductsToFilter, setCheckedProductsToFilter] = useState([]);
    const admin_panel_ctx = useContext(AdminPanelContext)

    useEffect(()=>{
        Calculate()
    },[])

    useEffect(()=>{
        let tableOfPhrases = searchInput.toLocaleLowerCase().split(' ')
        const foundProducts = document.querySelectorAll(".FoundProduct")
        let listOfProductToSet = [];
        foundProducts.forEach(product=>{
            let parentContainer = product.parentNode.parentNode;
            let show = true;
            tableOfPhrases.map(splittedPhrase=>{
                if(product.innerHTML.toLocaleLowerCase().indexOf(splittedPhrase) === -1){
                    show = false;
                }
            })
            if(show){
                parentContainer.style.display="block";
                listOfProductToSet.push(parseInt(product.dataset.id))
            }
            else{
                parentContainer.style.display="none"
            }
        })
        setCheckedProductsToFilter(listOfProductToSet)
    },[searchInput])


    const getAttributesForProduct = (id)=>{
        const data = {
            db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
            language:'pl',
            index:id,
        }
        PostMethod(GET_INDEX_ATTRIBUTES_URL, data, getAttributesResponse)
    }
    const getAttributesResponse = (response) =>{
        let currentAttributesInFoundList=[];
        let currentAttributesInFoundListIds = [];
        if(response.data){
            Object.keys(response.data).map(atr=>{
                currentAttributesInFoundList.push(response.data[atr])
                currentAttributesInFoundListIds.push(response.data[atr].id)
            })
        }
        let intersection  = currentAttributesInFoundListIds.filter(x => indexes_manager_ctx.removeAttributesInFoundChecked.includes(x));
        indexes_manager_ctx.setRemoveAttributesInFoundChecked(intersection)
        indexes_manager_ctx.setAttributesListInProductsInFound(currentAttributesInFoundList);
        setAttributesToRefresh(currentAttributesInFoundList);
    }

    const refreshAttributesInFound=()=>{
        let listToSet = [];
        let newList = indexes_manager_ctx.attributesListInSingleToAdd;
        let difference = newList.map(x=>{return x.id}).filter(x => !indexes_manager_ctx.attributesListInProductsInFound.map(x=>{return x.id}).includes(x));
        difference.map(dif=>{
            listToSet.push(newList[newList.map(x=>{return x.id}).indexOf(dif)])
        })
        indexes_manager_ctx.setAttributesListInSingleToAdd(listToSet)
        indexes_manager_ctx.setTimer(indexes_manager_ctx.timer +1)
    }

    useEffect(()=>{
        refreshAttributesInFound();
    }, [attributesToRefresh])

    useEffect(()=>{
        if(indexes_manager_ctx.isOnlyOneChosen){
            getAttributesForProduct(props.chosenProducts[0])
        }
        else{
            indexes_manager_ctx.setAttributesListInSingleToAdd([])
        }
    },[indexes_manager_ctx.isOnlyOneChosen, props.chosenProducts])

    const FoundProductElement = ({product}) =>{
        return(
            <Paper className={`${styles.found_product_element} ${indexes_manager_ctx.isControlPressed && styles.pointer}`} onClick={(e)=>{addingToGroup(product.id, e)}}>
                <div style={{backgroundColor:'white', marginBottom:'5px', borderRadius:'3px', display:'flex', justifyContent:'center'}}>{product.photo ? <img alt="product" src={`${product.photo.path}${product.photo.filename}`} loading="lazy"/> : <div style={{height:'70px', color:'black', paddingTop:'30px'}}></div>}</div>
                <Typography fontSize={10} sx={{height:'50px'}} className={'FoundProduct'} data-id={product.id}>{`${product.name} (${product.id})`}</Typography>
            </Paper>
        )
    }
    const FoundChosenProductElement = ({product}) =>{
        return(
            <Paper className={`${styles.found_chosen_product_element} ${indexes_manager_ctx.isControlPressed && styles.pointer}`} onClick={()=>{removeFromAdding(product.id)}}>
                <div style={{backgroundColor:'white', marginBottom:'5px', borderRadius:'3px', display:'flex', justifyContent:'center'}}>{product.photo ? <img alt="product" src={`${product.photo.path}${product.photo.filename}`} loading="lazy"/> : <div style={{height:'70px', color:'black', paddingTop:'30px'}}><MdOutlineNoPhotography size={40}/></div>}</div>
                <Typography fontSize={10} sx={{height:'50px'}} className={'FoundProduct'} data-id={product.id}>{`${product.name} (${product.id})`}</Typography>
            </Paper>
        )
    }
    const FoundInGroupProductElement = ({product}) =>{
        return(
            <Paper className={styles.found_in_group_product_element}>
                <div style={{backgroundColor:'white', borderRadius:'3px', display:'flex', justifyContent:'center', opacity:'0.7'}}>{product.photo ? <img style={{ maxWidth:'100%', borderRadius:'3px', height:'auto', maxHeight:'100px'}} alt="product" src={`${product.photo.path}${product.photo.filename}`} loading="lazy"/> : <div style={{height:'70px', color:'gray', paddingTop:'30px'}}><MdOutlineNoPhotography size={40}/></div>} </div>
                <Typography fontSize={10} sx={{height:'50px'}} className={'FoundProduct'} data-id={product.id}>{`${product.name} (${product.id})`}</Typography>
            </Paper>
        )
    }

    const setAllToChosen =()=>{
        props.foundProducts.map((prod)=>{
            if(props.chosenProducts.indexOf(prod.id) === -1 && indexes_manager_ctx.productsInGroup.indexOf(prod) === -1){
                let productsToSet = props.chosenProducts;
                productsToSet.push(prod.id);
                props.setChosenProducts(productsToSet);
                indexes_manager_ctx.setIsControlPressed(true);
                setTimeout(()=>{indexes_manager_ctx.setIsControlPressed(false)},5)
            }
        })
        indexes_manager_ctx.setTimer(indexes_manager_ctx.timer +1)
    }

    const addingToGroup = (id, event) => {
        if(props.chosenProducts.length <= 1 && !indexes_manager_ctx.isControlPressed){
            let productsToSet = [];
            productsToSet.push(id)
            props.setChosenProducts(productsToSet)
        }
        else if(props.chosenProducts.indexOf(id) === -1 && indexes_manager_ctx.isControlPressed){
            let productsToSet = props.chosenProducts;
            productsToSet.push(id);
            props.setChosenProducts(productsToSet);
        }
        event.target.style.backgroundColor = 'lightgreen !important'
        indexes_manager_ctx.setTimer(indexes_manager_ctx.timer +1)
    }

    const removeFromAdding = id => {
        if(props.chosenProducts.indexOf(id) !== -1 && indexes_manager_ctx.isControlPressed){
            let productsToSet = props.chosenProducts;
            productsToSet.splice(props.chosenProducts.indexOf(id),1)
            props.setChosenProducts(productsToSet)
        }indexes_manager_ctx.setTimer(indexes_manager_ctx.timer +1)
        
    }

    const addToGroup = () =>{
        let productsListToSet = indexes_manager_ctx.productsInGroup;
        props.chosenProducts.map(prod=>{
            props.foundProducts.map(foundProd=>{
                if(searchInput !==''){
                    if(prod === foundProd.id && checkedProductsToFilter.indexOf(prod) !== -1) productsListToSet.push(foundProd);
                }else if(prod === foundProd.id) productsListToSet.push(foundProd);
            })
        })
        indexes_manager_ctx.setProductsInGroup(productsListToSet);
        props.setChosenProducts([]);
        indexes_manager_ctx.setTimer(indexes_manager_ctx.timer +1)
    }

    const isFoundProductInGroup = (id)=>{
        let bool = false;
        indexes_manager_ctx.productsInGroup.map(prod=>{
            if(prod.id === id) bool = true;
        })
        return bool;
    }

    return (
            <Grid container>
                    <Grid container item xs={12} p={2} sx={{backgroundColor:admin_panel_ctx.cSheet.default}} id={"IndexesManagerFieldPanelActions"}>
                        <Grid item xs={3}>
                            <TextField placeholder="Szukaj w znalezionych" value={searchInput} onChange={(e)=>{setSearchInput(e.target.value)}} fullWidth/>
                        </Grid>
                        <Grid container item xs={5} alignContent={'center'} justifyContent={'center'}>
                            <Typography>Wszystkie:</Typography>
                            <Button sx={{height:'20px', color:"white"}} onClick={()=>setAllToChosen()}>zaznacz</Button>
                            <Typography>/</Typography>
                            <Button sx={{height:'20px', color:"white"}} onClick={()=>{props.setChosenProducts([]); indexes_manager_ctx.setTimer(indexes_manager_ctx.timer +1)}}>odznacz</Button>
                        </Grid>
                        <Grid container item xs={4} textAlign={'right'} alignContent={'center'} justifyContent={'center'}>
                            <Button variant="contained" onClick={()=>addToGroup()}>+ dodaj do grupy</Button>
                        </Grid>
                    </Grid>
                    <Grid container item xs={8} height={'200px'} className={'scrollerY'} sx={{overflow:'auto', display:'inline-block'}} p={1} id={"IndexesManagerFieldPanel"} >
                    {(props.foundProducts && props.foundProducts.length !== 0) ? 
                    <Grid container item id={'FoundProducts'} sx={{overflow:'hidden'}}>
                        {Object.keys(props.foundProducts).map(prod=>{
                            return(<Grid item xs={(admin_panel_ctx.leftPinActive || admin_panel_ctx.rightPinActive) ? 3 : 2} key={props.foundProducts[prod].id} p={1}>
                                        {props.chosenProducts.indexOf(props.foundProducts[prod].id) === -1 ? ( !isFoundProductInGroup(props.foundProducts[prod].id) ?  <FoundProductElement product={props.foundProducts[prod]}/> : <FoundInGroupProductElement product={props.foundProducts[prod]}/> ): <FoundChosenProductElement product={props.foundProducts[prod]}/>}
                                    </Grid>)
                        })}
                    </Grid>
                    :
                    <Grid item xs={12} textAlign={'center'}><Typography fontSize={20}>Brak wyników wyszukiwania</Typography></Grid>
                    }
                    </Grid>
                    {indexes_manager_ctx.boxGroupField !== 0 && <Grid item xs={4} className="BoxGroupFieldContainer"><IndexesManagerBoxGroupField/></Grid>}
            </Grid>
    )

}
export default IndexesManagerFoundField;